import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { DataService } from 'src/app/data.service';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Input } from '@angular/core';
import { ElementRef } from '@angular/core';
import {RatingModule} from "ngx-rating";
import { Form } from '@angular/forms';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.css']
}

)
export class TicketDetailComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  departmentId:any;
  data:any;
  form: FormGroup;
  ticket:any=null;
  officials:any = [];
  selected:any=[];
  formUpdate:any;
  formResponse:any;
  selectedOfficials: any=null;
  display:any='none';
  details:any;
  formRating:any;

  rateCount:any;
  rateDescription:string='';
  ratingForm:FormGroup;


  constructor(private fb: FormBuilder,public apiService:ApiService,public dataService:DataService,public authService:AuthService,public router:Router)
  {
        this.form = this.fb.group({
          selected: ['', Validators.required]
      });
    this.ratingForm = this.fb.group({
      rateDescription: ['', Validators.required]
    });
  }


  ngOnInit() {
  this.rateCount=0;
  var selected_ticket = localStorage.getItem('current_ticket');
  const selectedTicket=JSON.parse(selected_ticket);
  this.connectionManager.get('/tickets/'+selectedTicket.id+'?include=citizen,query-type.department,ward.sub-county,official,responses.official,rating,share-approved-by,completed-by,started-by',res=>
  {
    //console.log("image" + res.data.image);
    this.ticket = res.data
    this.formRating = res.data.rating.stars;
    this.getOfficials();


  });
    this.connectionManager.get('/tickets/create',res=>
    {
      this.data = res.data;
    })
  }


  getOfficials()
  {
   if(this.authService.isAdmin())
   {
    this.connectionManager.get('/users?type=OFFICIAL&before=0&after=0&perPage=1000&recent=true',res=>
    {
      this.officials = res.data
    });
   }

  }

  shareToTeam()
  {
    this.dataService.setCurrentTicket(this.ticket);
    this.router.navigateByUrl('/shareticket');
  }
  openAssignModal()
  {
    this.display="block";

  }
  assign()
  {

    const val = this.form.value;
   // console.log(val.selected);

    this.connectionManager.post('/tickets/'+this.ticket.id+'/officials',res=>
    {
      this.selectedOfficials = res.data
      this.display='none';
      // this.router.navigateByUrl('/tickets');
    },{officials:val.selected})
  }
  forwardTicket(ticket,selectedQueryType){
    if(confirm("Are you sure you want to forwad this message to?" + selectedQueryType.name)){
    // alert(ticket.number);
      if(ticket.status==='complete'){
        alert("You cannot forward a completed ticket");
        return;
      }else {
        this.connectionManager.put(
          '/tickets/'+
          this.ticket.id+'/forward/' + selectedQueryType.id,res=>
          {
            alert(res.meta.message);
            this.ticket = res.data
            this.router.navigateByUrl('/tickets');
          },{})
      }
    }
  }
  updateStatus(ticketstatus)
  {

    if(ticketstatus!=='')
    {
      // console.log('there')
      if(ticketstatus ==='start')
      {
        console.log('started')
          this.connectionManager.post('/tickets/'+this.ticket.id+'/start?include=citizen,query-type,ward.sub-county,official,responses.official,rating,share-approved-by,completed-by,started-by',res=>
        {
          this.ticket = res.data
        },{})
      }
      else if(ticketstatus === 'complete')
      {
        console.log('complete')
        this.connectionManager.post('/tickets/'+this.ticket.id+'/complete?include=citizen,query-type,ward.sub-county,official,responses.official,rating,share-approved-by,completed-by,started-by',res=>
        {
          this.ticket = res.data
        },{})
      }
    }else
    {
      console.log('not status')
    }
  }
  approveShareTicket()
  {
    this.connectionManager.post('/tickets/'+this.ticket.id+'/approveShare?include=citizen,query-type,ward.sub-county,official,responses.official,rating,share-approved-by,completed-by,started-by',res=>
    {
      this.ticket = res.data
    },{})
  }
  sendResponse()
  {
    this.connectionManager.post('/tickets/'+this.ticket.id+'/responses?include=official,citizen',res=>
    {
      this.details='';
      this.ticket.responses.push(res.data);


    },{details:this.details})
  }
  sendRating()
  {
    this.connectionManager.post('/tickets/'+this.ticket.id+'/ratings',res=>
    {
      this.ticket.rating = res.data;
      this.formRating = res.data.stars;
      this.rateDescription='';
    },{stars:this.rateCount,
      text:this.rateDescription
    })
  }
  assignOfficial()
  {
    this.dataService.setCurrentTicket(this.ticket);
      localStorage.setItem('current_ticket', JSON.stringify(this.ticket));
      this.router.navigateByUrl('/assignofficial');
  }
}
