import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { SimpleChange } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.css']
})
export class NewTicketComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  data:any;
  subCounty: any;
  ward: any;
  wards:any;
  queryTypeId:any;
  subject:any;
  details:any;
  form:FormGroup;
  selectedFile: File;

  constructor(public http:HttpClient,private fb: FormBuilder,private apiService:ApiService,private router: Router,public authService:AuthService)
  {
    this.form = this.fb.group({
      subject: ['', Validators.required],
      queryTypeId: ['', Validators.required],
      subCountyId: ['', Validators.required],
      wardId: ['', Validators.required],
      details: ['', Validators.required],
      name: [],
      phone: [],
      inputMode: []
  });
   }

  ngOnChanges(changes: SimpleChange){
    console.log(SimpleChange);
    alert("gggggg")
      }

  ngOnInit() {
    this.connectionManager.get('/queryTypes',res=>
    {
      this.data = res.data;
    });

  this.connectionManager.get('/tickets/create',res=>
  {
    this.data = res.data;
    this.subCounty = res.data.subCounties[0];
  });
  }
  submit()
  {
    const val = this.form.value;

    this.connectionManager.post('/tickets',res=>
    {
      alert(res.meta.message);
      this.router.navigateByUrl('/tickets');
    },{
      subject:val.subject,
      queryTypeId:val.queryTypeId,
      subCountyId:val.subCountyId,
      wardId:val.wardId,
      details:val.details,
      name:val.name,
      phone:val.phone,
      inputMode:val.inputMode,
      image:this.selectedFile
    })

  }
  onChange(subCountyId) {
  this.connectionManager.get('/subcounties/'+subCountyId+'/wards',res=>{
    this.wards = res.data;
  })
}

onFileChanged(event) {
  this.selectedFile = event.target.files[0];
}



}
