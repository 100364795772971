import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private currentTicket:any;
  currentTopic:any;
  user:any;
  private currentUser:any;
  public currentDepartment:any;
  public currentContactGroup:any;
  public currentSms:any;

  constructor(public router:Router) { }
getCurentTicket()
{
  return this.currentTicket;
}

  setCurrentTicket(ticket:Object)
  {
    this.currentTicket=ticket;
  }
  viewTopic(topic)
  {
    this.currentTopic=topic;
    this.router.navigateByUrl('/contributions');
  }
  getUserType()
  {
    var string_user = localStorage.getItem('nccms_user');
    this.user=JSON.parse(string_user);
    if(this.user.type=='CITIZEN')
    {
      return 'citizen';
    }
    else if(this.user.type=='OFFICIAL')
    {
      return 'official';
    }
    else if(this.user.type=='ADMIN')
    {
      return 'admin';
    }

  }
  setCurrentUser(user:Object)
  {
    this.currentUser = user;
  }
  getCurrentUser()
  {
    return this.currentUser;
  }
  setCurrentDepartment(department:Object)
  {
    this.currentDepartment = department;
  }
  getCurrentDepartment()
  {
    return this.currentDepartment;
  }
  setCurrentContactGroup(contactGroup:Object)
  {
    this.currentContactGroup = contactGroup;
  }
  getCurrentContactGroup()
  {
    return this.currentContactGroup;
  }


  setCurrentSms(sms:Object)
  {
    this.currentSms = sms;
  }
  getCurrentSms()
  {
    return this.currentSms;
  }
}
