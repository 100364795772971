import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-queryTypes',
  templateUrl: './queryTypes.component.html',
  styleUrls: ['./queryTypes.component.css']
})
export class QueryTypesComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  queryTypes:any;
  queryType:any;


  constructor(public apiService:ApiService,public router:Router,public dataService:DataService) { }

  ngOnInit() {
    this.connectionManager.get('/queryTypes?include=last-edited-by,department,created-by,deleted-by', res=>{
      this.queryTypes = res.data;
  })

}
  viewQueryType(queryType:Object)
{
  this.dataService.setCurrentDepartment(queryType);
  this.router.navigateByUrl('/queryTypes');
}

  editviewQueryType(queryType:Object)
{
  this.dataService.setCurrentDepartment(queryType);
  this.router.navigateByUrl('/queryTypes');
}


}
