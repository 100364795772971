import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { FormGroup } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

 
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  form: FormGroup;
  TOKEN_KEY = 'token';
  USER_DATA = 'nccms_user'

  constructor(private fb: FormBuilder, private authService: AuthService,public router:Router) {
      this.form = this.fb.group({
          phone: ['', Validators.required]
      });
  }

  ngOnInit() {
  }
  recoverPassword()
  {
    const val = this.form.value;
    
            this.connectionManager.post('/auth/password/recover', res => {
                this.authService.saveUser(res.data)
                console.log(res);
                localStorage.setItem('phone_verify', res.data.phone);
                this.router.navigateByUrl('/resetpassword');
            },{
                phone: val.phone
            });
  }

}
