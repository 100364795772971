import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { ViewChild } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-list-detail',
  templateUrl: './contact-list-detail.component.html',
  styleUrls: ['./contact-list-detail.component.css']
})
export class ContactListDetailComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  contactGroup:any;


  constructor(public dataService:DataService,public router:Router) { }

  ngOnInit() {
    this.contactGroup= this.dataService.getCurrentContactGroup();
  }
  deleteContactGroup()
  {
    if(confirm("Are you sure to delete the contact group?")) {
    this.connectionManager.delete('/contactGroups/'+this.contactGroup.id,res=>
    {
      this.router.navigateByUrl('/contactList');
    })
    }

  }
}
