import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  user:any;

  constructor( private router: Router,public authService:AuthService) { }

  ngOnInit() {
    var string_user = localStorage.getItem('nccms_user');
    this.user=JSON.parse(string_user);
  }
 
  logout()
  {
    localStorage.removeItem('token');
    localStorage.removeItem('nccms_user');
    
    this.router.navigateByUrl('/login');
  }
  changePassword()
  {
    this.router.navigateByUrl('/changepassword');
  }
  deleteAccount()
  {
    if(confirm("Are you sure to delete Account?")) {
    this.connectionManager.delete('/auth',res=>
    {
      this.authService.logout();
    })
    }
    // console.log('deleted');

  }
}
