import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-citizen-tickets',
  templateUrl: './citizen-tickets.component.html',
  styleUrls: ['./citizen-tickets.component.css']
})
export class CitizenTicketsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
