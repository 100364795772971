import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-bulk-sms',
  templateUrl: './bulk-sms.component.html',
  styleUrls: ['./bulk-sms.component.css']
})
export class BulkSmsComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  bulksms= [];
  perPage = 5;
  before = 0;
  after = 0;
  recent = true;
  isOngoing=false;
  

  constructor(public apiService:ApiService,public router:Router,public dataService:DataService) { }

  ngOnInit() {
    this.isOngoing=true;
    this.connectionManager.get('/bulkSms?include=created-by&before=0&after=0&perPage=5&recent=true', res=>{
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      this.bulksms=res.data;
      this.isOngoing=false;
    
  })
   
}

addItems() {
  if(this.isOngoing==false)
  {
    this.isOngoing=true;
    console.log('Requesting more before = '+this.before)
    this.connectionManager.get('/bulkSms?include=created-by&before='+this.before+'&after='+this.after+'&perPage=5&recent=false', res=>{
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      for(let item of res.data){
        this.bulksms.push(item)
      }
      this.isOngoing=false;
  
  })
  }

}


onScrollDown () {
  this.addItems()
  // this.direction = 'down'
}


setSms(sms:Object)
{
  this.dataService.setCurrentSms(sms);
  this.router.navigateByUrl('/sms');
}




}
