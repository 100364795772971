import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  user:any;
  loggedInUser:any;

  constructor(public dataService:DataService,public router:Router) { }

  ngOnInit() {
    this.user = this.dataService.getCurrentUser();

    var string_user = localStorage.getItem('nccms_user');
    this.loggedInUser=JSON.parse(string_user);
  }
  deleteUser()
  {
    if(confirm("Are you sure to delete this User?")) {
    this.connectionManager.delete('/users/'+this.user.id,res=>
    {
      this.router.navigateByUrl('/users');
    })
    }

  }
  disableUser(){
    if(confirm("Are you sure to disable this User?")) {
      this.connectionManager.delete('/users/'+this.user.id,res=>
      {
        this.router.navigateByUrl('/users');
      })
    }
  }

}
