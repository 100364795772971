import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ViewChild } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { Router } from '@angular/router';
import { AuthService as SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider, LinkedInLoginProvider } from "angularx-social-login";
import { SocialUser } from 'angularx-social-login/src/entities/user';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
    form: FormGroup;
    TOKEN_KEY = 'token';
    USER_DATA = 'nccms_user';
    socialUser:any;

    constructor(private fb: FormBuilder, private authService: AuthService,public router:Router,public socialAuthService:SocialAuthService) {
        this.form = this.fb.group({
            phone: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    ngOnInit() {
    }

    login() {


        const val = this.form.value;

        this.connectionManager.post('/auth/signIn', res => {
            this.authService.saveUser(res.data)
            localStorage.setItem('phone_verify', res.data.phone);
            console.log(res);
            this.router.navigateByUrl('/dashboard');
        }, {
            signInId: val.phone,
            password: val.password
        });
    }

    recoverPassword()
    {
        this.router.navigateByUrl('/recoverpassword');
    }


    signInWithGoogle(): void {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user)=>{
            this.socialUser = user;
            console.log(user);
            this.connectionManager.post('/auth/google', res => {
                this.authService.saveUser(res.data)
                localStorage.setItem('phone_verify', res.data.phone);
                console.log(res);
                this.router.navigateByUrl('/dashboard');
            }, {
                name: user.name,
                email: user.email,
                facebookId:user.id,
                photoUrl:user.photoUrl,


            });
        });

      }

      signInWithFB(): void {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
            this.socialUser = user;
            console.log(user);
            this.connectionManager.post('/auth/facebook', res => {
                this.authService.saveUser(res.data)
                localStorage.setItem('phone_verify', res.data.phone);
                console.log(res);
                this.router.navigateByUrl('/dashboard');
            }, {
                name: user.name,
                email: user.email,
                facebookId:user.id,
                photoUrl:user.photoUrl,


            });
          });

      }

      signInWithLinkedIn(): void {
        this.socialAuthService.signIn(LinkedInLoginProvider.PROVIDER_ID);
      }
}
