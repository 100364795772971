import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { error } from '@angular/compiler/src/util';
import { Key } from 'selenium-webdriver';
import { AuthService } from 'src/app/auth.service';
// import { Http } from '@angular/http/src/http';

@Component({
  selector: 'app-connection-manager',
  templateUrl: './connection-manager.component.html',
  styleUrls: ['./connection-manager.component.css']
})
export class ConnectionManagerComponent implements OnInit {
  connecting:boolean=false;
  errorMessages:any = [];
  //BASE_URL:string='http://a582b618.ngrok.io/api/v1';
  BASE_URL:string='http://complaint-api.nyandarua.go.ke/api/v1';
  URL:string='http://complaint-api.nyandarua.go.ke';

  //http://complaint-api.nyandarua.go.ke/api/v1

  constructor(public http:HttpClient,public router:Router, public authService:AuthService) { }

  ngOnInit() {
  }

  handleError(errorResponse, status){
    console.log(errorResponse)
    this.connecting = false;
    this.errorMessages = []
    if(!errorResponse || status===0){
      this.errorMessages.push('Unknown error occured. Please check your internet connection.')
    } else if(status == 401){
      this.authService.logout()
    }
    else if(status === 500){
      this.errorMessages.push(errorResponse.message)
    }
    else if(status === 400 && errorResponse.meta.code === 'VALIDATION'){
      let keys = Object.keys(errorResponse.data);
      for(let key of keys){
        let item = errorResponse.data[key];
        this.errorMessages.push(item[0])
      }
    } else if(errorResponse.meta.code === 'PHONE_NOT_VERIFIED'){
      localStorage.setItem('phone_verify', errorResponse.data.phone);
      this.router.navigateByUrl('/verify');
    } else {
      this.errorMessages.push(errorResponse.meta.message)
    }
  }

  onStartConnecting(){
    this.connecting = true;
    this.errorMessages = [];
  }

  onFinishConnecting(response,successCallback ){
    this.connecting = false;
    this.errorMessages = [];
    console.log(response);
    successCallback(response)
  }

  post(relativeUrl,successCallback,params)
  {
    let f_url = this.appendUrl(this.BASE_URL+relativeUrl);
    console.info(params)
    this.onStartConnecting();
    this.http.post(f_url,params).subscribe(
      response => {
        this.onFinishConnecting(response, successCallback)
    },
    errorResponse => {
      console.log(errorResponse)
        this.handleError(errorResponse.error, errorResponse.status)
    }
  )
  }
  put(relativeUrl,successCallback,params)
  {
    let f_url = this.appendUrl(this.BASE_URL+relativeUrl);
    console.info(params)
    this.onStartConnecting();
    this.http.put(f_url,params).subscribe(
      response => {
        this.onFinishConnecting(response, successCallback)
    },
    errorResponse => {
      console.log(errorResponse)
        this.handleError(errorResponse.error, errorResponse.status)
    }
  )
  }
  delete(relativeUrl,successCallback)
  {
    let f_url = this.appendUrl(this.BASE_URL+relativeUrl);
    this.onStartConnecting();
    this.http.delete(f_url).subscribe(
      response => {
        this.onFinishConnecting(response, successCallback)
    },
    errorResponse => {
      console.log(errorResponse)
        this.handleError(errorResponse.error, errorResponse.status)
    }
  )
  }
  get(relativeUrl,successCallback)
  {
    let f_url = this.appendUrl(this.BASE_URL+relativeUrl);
    this.onStartConnecting();
    this.http.get(f_url).subscribe(
      response => {
        this.onFinishConnecting(response, successCallback)
    },
    errorResponse => {
      console.log(errorResponse)
        this.handleError(errorResponse.error, errorResponse.status)
    }
  )
  }


appendUrl(url)
{
  var finalUrl='';
  if (url.includes('?'))
{
  var token = localStorage.getItem('token');
  finalUrl=url+"&token="+token;

}
else
{
  var token = localStorage.getItem('token');
  finalUrl=url+"?token="+token;
}

return finalUrl;


}

}
