import { Component, OnInit, ViewChild } from '@angular/core';
import { ConnectionManagerComponent } from '../connection-manager/connection-manager.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
    summary:any;
    wards:any;
    subCounties:any;
    queryTypeId:number=0;
    queryTypes:any;

  constructor() { }

  ngOnInit() {
    this.loadSummary();
  }
  loadSummary()
  {
    this.connectionManager.get('/tickets/summary?queryTypeId='+this.queryTypeId, res=>{
      this.summary= res.data;
      console.log(res.data)

      this.loadQueryTypes();
      this.loadSubCounties();

  })
  }
  loadSubCounties()
  {
    this.connectionManager.get('/subcounties',res=>{
      this.subCounties= res.data;
    })
  }

  loadQueryTypes()
  {
    this.connectionManager.get('/queryTypes',res=>{
      this.queryTypes= res.data;
    })
  }
  showPerWard(ward)
  {
    if(ward)
    {
      this.connectionManager.get('/tickets/summary?filter=ward&wardId='+ward+'&queryTypeId='+this.queryTypeId, res=>{
        this.summary= res.data;
        console.log(res.data)
    })
    }

  }

  showPerQueryType(queryTypeId)
  {
    this.connectionManager.get('/tickets/summary?queryTypeId='+queryTypeId, res=>{
      this.queryTypeId=queryTypeId;
      this.summary= res.data;
      console.log(res.data)
  })
  }

  showPerSubCounty(subCounty)
  {
    if(subCounty)
    {
      this.connectionManager.get('/tickets/summary?filter=subCounty&subCountyId='+subCounty+'&queryTypeId='+this.queryTypeId, res=>{
        this.summary= res.data;
        console.log(res.data)
    })
    }

  this.loadWards(subCounty);
  }
  loadWards(subCounty)
  {
    if(subCounty)
    {
      this.connectionManager.get('/subcounties/'+subCounty+'/wards',res=>{
        this.wards = res.data;
      })
    }

  }

}
