import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { FormGroup } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {


  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  form: FormGroup;
  TOKEN_KEY = 'token';
  USER_DATA = 'nccms_user'
  user_phone:any;

  constructor(private fb: FormBuilder, private authService: AuthService,public router:Router) {
      this.form = this.fb.group({
        code: ['', Validators.required],
          newPassword: ['', Validators.required],
          confirmNewPassword: ['', Validators.required]
      });
  }

  ngOnInit() {
    var string_phone = localStorage.getItem('phone_verify');
    this.user_phone=string_phone;
  }
  resetPassword()
  {
    const val = this.form.value;
    
            this.connectionManager.post('/auth/password/reset', res => {
                this.authService.saveUser(res.data)
                console.log(res);
                this.router.navigateByUrl('/dashboard');
            },{
                code: val.code,
                phone: this.user_phone,
                password: val.newPassword,
                password_confirmation:val.confirmNewPassword
            });
  }

}
