import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css']
})
export class DepartmentsComponent implements OnInit {
  departments: any;
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  
  constructor(public apiService:ApiService,public router:Router,public dataService:DataService) { }

  ngOnInit() {
    this.connectionManager.get('/departments?include=last-edited-by,created-by,deleted-by', res=>{
      this.departments = res.data;
  })
   
}
viewDepartment(department:Object)
{
  this.dataService.setCurrentDepartment(department);
  this.router.navigateByUrl('/department');
}

editDepartment(department:Object)
{
  this.dataService.setCurrentDepartment(department);
  this.router.navigateByUrl('/editdepartment');
}


}
