import { Injectable } from '@angular/core';
import { RequestOptions, Response } from '@angular/http';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';

@Injectable()
export class ApiService {

    API_URL = 'http://complaint-api.nyandarua.go.ke/api/v1';
    TOKEN_KEY = 'token';

    constructor(private http: HttpClient, private router: Router) { }

    get token() {
        return localStorage.getItem(this.TOKEN_KEY);
    }

    get isAuthenticated() {
        return !!localStorage.getItem(this.TOKEN_KEY);
    }

    logout() {
        localStorage.removeItem(this.TOKEN_KEY);
        this.router.navigateByUrl('/');
    }

    getAccount() {
        return this.http.get(this.API_URL + '/dashboard');
    }
    getLatestTickets()
    {
        return this.http.get(this.API_URL + '/tickets?filter=latest&include=department');

    }
    getTicketInitialData()
    {
        return this.http.get(this.API_URL + '/tickets/create');
    }
    getTicketWardData(subCountyId)
    {
        return this.http.get(this.API_URL + '/subcounties/'+subCountyId+'/wards');
    }
    addTicket(subject,departmentId,subCountyId,wardId,details)
    {
        const headers = {
            headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
        };

        
        let body = "subject="+subject + "&departmentId=" + departmentId + "&subCountyId="+subCountyId + "&wardId=" + wardId + "&details=" + details;

        this.http.post(this.API_URL + '/tickets',body, headers).subscribe(
            (res: any) => {
                console.log(res);
                this.router.navigateByUrl('/dashboard');
            }
        );
    }

    getTickets()
    {
        return this.http.get(this.API_URL + '/tickets?include=citizen,department');
    }
    getTicketDetail()
    {
        
    }
}