import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-edit-official',
  templateUrl: './edit-official.component.html',
  styleUrls: ['./edit-official.component.css']
})
export class EditOfficialComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;

  user:any;
  
  form:FormGroup;
  name:string;
  phone:string;
  email:string;
  type:string;
  departmentId:any;
  smsNotificationsEnabled:boolean=true;
  departments:any;
  isOfficial:boolean=false;

  
    constructor(public dataService:DataService,private fb: FormBuilder,private apiService:ApiService,private router: Router,public authService:AuthService) 
    {
      this.form = this.fb.group({
        name: ['', Validators.required],
        phone: ['', Validators.required],
        email: []
        
    });
     }
  
    ngOnInit() {
      this.user = this.dataService.getCurrentUser();
      this.name = this.user.name
      this.phone = this.user.phone
      this.email = this.user.email
      this.type = this.user.type
      
      
    }
    updateUser()
    {
      var departmentId = this.authService.user.departmentId;
      this.type = "OFFICIAL";

      this.connectionManager.put('/users/'+this.user.id, res => {
        this.dataService.setCurrentUser(res.data);
        this.router.navigateByUrl('/user');
    },{
        name:  this.name,
        phone: this.phone,
        email:  this.email,
        type:  this.type,
        departmentId:departmentId,
        smsNotificationsEnabled:this.smsNotificationsEnabled
    });
    }
    public smsNotificationsEnabledChanged(value:boolean){
      this.smsNotificationsEnabled = value;
      console.log(value);
  }

  

}
