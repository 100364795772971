import {Component, OnInit, ViewChild} from '@angular/core';
import {ConnectionManagerComponent} from '../connection-manager/connection-manager.component';

@Component({
  selector: 'app-trending-reports',
  templateUrl: './trending-reports.component.html',
  styleUrls: ['./trending-reports.component.css']
})
export class TrendingReportsComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager: ConnectionManagerComponent;

  trendingTickets: any;


  constructor() {
  }

  ngOnInit() {
    this.loadTrendingQueryTypes();
  }

  loadTrendingQueryTypes() {
    this.connectionManager.get('/queryTypes/trendingTickets', res => {
      this.trendingTickets = res.data;
      console.log(res.data);


    });
  }

}
