import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  form:FormGroup;
  isOfficial:boolean=false;
  departments:any;
  smsNotificationsEnabled:any=true;
  isDepartmentAdmin:any;
  type:any;

  constructor(private fb: FormBuilder,public router:Router,public authService:AuthService) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      type: [],
      departmentId:[]

  });
  }

  getType(type){

  }

  ngOnInit() {
    this.isDepartmentAdmin = this.authService.isDepartmentAdmin();

    if(!this.isDepartmentAdmin)
    {
      this.connectionManager.get('/departments', res=>{
        this.departments = res.data;
        console.log(res.data);
    })
    }

  }
  addUser()
  {
    const val = this.form.value;

      var departmentId= val.departmentId;
      this.type = val.type;


    this.connectionManager.post('/users', res => {
      this.router.navigateByUrl('/users');
  },{
      phone: val.phone,
      name:  val.name,
      email:  val.email,
      type:  this.type,
      departmentId:  departmentId,
      smsNotificationsEnabled:this.smsNotificationsEnabled
  });
  }
  showDepartment(user)
  {
    if(user!='CITIZEN')
    {
      this.isOfficial=true;
    }else
    {
      this.isOfficial=false;
    }

  }
  public smsNotificationsEnabledChanged(value:boolean){
    this.smsNotificationsEnabled = value;
    console.log(value);
}


}
