import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
    @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
    form: FormGroup;
    TOKEN_KEY = 'token';
    USER_DATA = 'nccms_user'
    user_phone :any;
  
      constructor(private fb: FormBuilder, private authService: AuthService,public router:Router) {
          this.form = this.fb.group({
              otp: ['', Validators.required]
          });
      }

  ngOnInit() {
    var string_phone = localStorage.getItem('phone_verify');
    this.user_phone=string_phone;

  }
  verify() {
            const val = this.form.value;
    
            this.connectionManager.post('/auth/verification', res => {
                this.authService.saveUser(res.data)
                console.log(res);
                this.router.navigateByUrl('/dashboard');
            }, {
                phone: this.user_phone,
                code: val.otp
            });
        }
    resend()
    {
        this.connectionManager.get('/auth/code?phone='+this.user_phone, res => {
    
        }); 
    }

}
