import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { ViewChild } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sms-detail',
  templateUrl: './sms-detail.component.html',
  styleUrls: ['./sms-detail.component.css']
})
export class SmsDetailComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  sms:any;
 

  constructor(public dataService:DataService,public router:Router) { }

  ngOnInit() {
    this.sms= this.dataService.getCurrentSms();
  }

}
