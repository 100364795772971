import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { NewTicketComponent } from './new-ticket/new-ticket.component';
import { CitizenProfileComponent } from './citizen-profile/citizen-profile.component';
import { CitizenTicketsComponent } from './citizen-tickets/citizen-tickets.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AppRoutingModule } from './/app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { HttpModule } from '@angular/http';
import {HttpClientModule} from '@angular/common/http'
// import { HttpClient } from '@angular/common/http';
import { AuthInterceptorService } from './auth-interceptor.service';
import { CanActivateViaAuthGuard } from './can-activate-via-auth.guard';
import { ApiService } from 'src/app/api.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { HashLocationStrategy } from '@angular/common';
import { LocationStrategy } from '@angular/common';
import { OtpComponent } from './otp/otp.component';
import { ConnectionManagerComponent } from './connection-manager/connection-manager.component';
import { ForumComponent } from './forum/forum.component';
import { ForumDetailComponent } from './forum-detail/forum-detail.component';
import { UsersComponent } from './users/users.component';
import { NewUserComponent } from './new-user/new-user.component';
import { ShareTicketComponent } from './share-ticket/share-ticket.component';
import { AddTopicComponent } from './add-topic/add-topic.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { BarRatingModule } from "ngx-bar-rating";
import { RatingModule } from 'ngx-rating';
import { DepartmentsComponent } from './departments/departments.component';
import { DepartmentDetailComponent } from './department-detail/department-detail.component';
import { EditDepartmentComponent } from './edit-department/edit-department.component';
import { NewDepartmentComponent } from './new-department/new-department.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider, LinkedInLoginProvider} from "angularx-social-login";
import { AssignTicketComponent } from './assign-ticket/assign-ticket.component';
import { BulkSmsComponent } from './bulk-sms/bulk-sms.component';
import { SendSmsComponent } from './send-sms/send-sms.component';
import { SmsDetailComponent } from './sms-detail/sms-detail.component';
import { NewOfficialComponent } from './new-official/new-official.component';
import { EditOfficialComponent } from './edit-official/edit-official.component';
import { ReportsComponent } from './reports/reports.component';
import { OfficialsComponent } from './officials/officials.component';
import {QueryTypesComponent} from './queryTypes/queryTypes.component';
import {NewQueryTypeComponent} from './new-query-type/new-query-type.component';
import {ContactListComponent} from './contact-list/contact-list.component';
import {ContactListDetailComponent} from './contact-list-detail/contact-list-detail.component';
import {NewContactListComponent} from './new-contact-list/new-contact-list.component';
import {TrendingReportsComponent} from './trending-reports/trending-reports.component';
import {TrendingTopicsReportsComponent} from './trending-topics-reports/trending-topics-reports.component';


let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("703614867558-079mvrpb9dobvopauh6jcktbn7pumihq.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("2133066166764182")
  },
  {
    id: LinkedInLoginProvider.PROVIDER_ID,
    provider: new LinkedInLoginProvider("LinkedIn-client-Id", false, 'en_US')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    NewTicketComponent,
    CitizenProfileComponent,
    CitizenTicketsComponent,
    UserProfileComponent,
    ResetPasswordComponent,
    RecoverPasswordComponent,
    ChangePasswordComponent,
    TicketsComponent,
    TicketDetailComponent,
    OtpComponent,
    ConnectionManagerComponent,
    ForumComponent,
    ForumDetailComponent,
    UsersComponent,
    NewUserComponent,
    ShareTicketComponent,
    AddTopicComponent,
    UserDetailComponent,
    EditUserComponent,
    DepartmentsComponent,
    DepartmentDetailComponent,
    EditDepartmentComponent,
    NewDepartmentComponent,
    AssignTicketComponent,
    BulkSmsComponent,
    SendSmsComponent,
    SmsDetailComponent,
    NewOfficialComponent,
    EditOfficialComponent,
    ReportsComponent,
    OfficialsComponent,
    QueryTypesComponent,
    NewQueryTypeComponent,
    ContactListComponent,
    ContactListDetailComponent,
    NewContactListComponent,
    TrendingReportsComponent,
    TrendingTopicsReportsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    RatingModule,
    BarRatingModule,
    SocialLoginModule,
    InfiniteScrollModule
  ],
  exports: [HomeComponent,NavComponent,ReactiveFormsModule],
  providers: [
    AuthService,
    ApiService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    CanActivateViaAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
