import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/data.service';
import { ApiService } from 'src/app/api.service';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.css']
})
export class EditDepartmentComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  
    department:any;
    
    form:FormGroup;
    name:string;
    description:any;
    
      constructor(public dataService:DataService,private fb: FormBuilder,private apiService:ApiService,private router: Router,public authService:AuthService) 
      {
        this.form = this.fb.group({
          name: ['', Validators.required],
          description:[]

          
      });
       }
    
      ngOnInit() {
        this.department = this.dataService.getCurrentDepartment();
        this.name = this.department.name;
        this.description= this.department.description
    
      }
      updateDepartment()
      {
        const val = this.form.value;
        this.connectionManager.put('/departments/'+this.department.id+'?include=last-edited-by,created-by,deleted-by', res => {
          this.dataService.setCurrentDepartment(res.data);
          this.router.navigateByUrl('/department');
      },{
          name:  this.name,
          description:  this.description
      });
      }

}
