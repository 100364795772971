import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-topic',
  templateUrl: './add-topic.component.html',
  styleUrls: ['./add-topic.component.css']
})
export class AddTopicComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  form:FormGroup;
  queryTypes:any;

  constructor(private fb: FormBuilder,public router:Router) {
    this.form = this.fb.group({
      queryTypeId: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required]


  });
   }

  ngOnInit() {
    this.connectionManager.get('/topics/create', res=>{
      console.log(res);
      this.queryTypes = res.data;
  })
  }
  addTopic()
  {
    const val = this.form.value;

    this.connectionManager.post('/topics',res=>
    {
      this.router.navigateByUrl('/forum');
    },{
      queryTypeId:val.queryTypeId,
      title:val.title,
      description:val.description
    })

  }

}
