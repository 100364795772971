import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-officials',
  templateUrl: './officials.component.html',
  styleUrls: ['./officials.component.css']
})
export class OfficialsComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  users= [];
  perPage = 3;
  before = 0;
  after = 0;
  recent = true;
  isOngoing=false;
  

  constructor(public apiService:ApiService,public router:Router,public dataService:DataService) { }

  ngOnInit() {
    this.isOngoing=true;
    this.connectionManager.get('/users?before=0&after=0&perPage=3&recent=true', res=>{
      this.isOngoing=false;
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      this.users=res.data;
    
  })
   
}

addItems() {
  if(this.isOngoing==false)
  {
    this.isOngoing=true;
    console.log('Requesting more before = '+this.before)
    this.connectionManager.get('/users?before='+this.before+'&after='+this.after+'&perPage=3&recent=false', res=>{
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      for(let item of res.data){
        this.users.push(item)
      }
      this.isOngoing=false;
  
  })
  }

}


onScrollDown () {
  this.addItems()
  // this.direction = 'down'
}


setUser(user:Object)
{
  this.dataService.setCurrentUser(user);
  this.router.navigateByUrl('/user');
}

editUser(user:Object)
{
  this.dataService.setCurrentUser(user);
  this.router.navigateByUrl('/editofficial');
}


}
