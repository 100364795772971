import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ViewChild } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { Router } from '@angular/router';
import { AuthService as SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider, LinkedInLoginProvider } from "angularx-social-login";
import { SocialUser } from 'angularx-social-login/src/entities/user';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;

  form: FormGroup;
  PHONE_VERIFY_NUMBER='phone_verify';
  socialUser:any;
  smsNotificationsEnabled:boolean = true;
  
      constructor(private fb: FormBuilder, private authService: AuthService,public router:Router,public socialAuthService:SocialAuthService) {
          this.form = this.fb.group({
            names: ['', Validators.required],
              phone: ['', Validators.required],
              password: ['', Validators.required],
              passwordReenter: ['', Validators.required]
          });
      }

  ngOnInit() {
  }
  register() {
    const val = this.form.value;
    
            this.connectionManager.post('/auth/signUp', res => {
                localStorage.setItem(this.PHONE_VERIFY_NUMBER, res.data.phone);
                this.router.navigateByUrl('/verify');
            },{
                phone: val.phone,
                name:  val.names,
                password: val.password,
                password_confirmation: val.passwordReenter,
                smsNotificationsEnabled:this.smsNotificationsEnabled
                

            });
}
    
signUpWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
        this.socialUser = user;
        console.log(user);
        this.connectionManager.post('/auth/facebook', res => {
            this.authService.saveUser(res.data)
            localStorage.setItem('phone_verify', res.data.phone);
            console.log(res);
            this.router.navigateByUrl('/dashboard');
        }, {
            name: user.name,
            email: user.email,
            facebookId:user.id,
            photoUrl:user.photoUrl,


        });
      });
    
  }
  public smsNotificationsEnabledChanged(value:boolean){
    this.smsNotificationsEnabled = value;
    console.log(value);
}

}
