import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { NewTicketComponent } from 'src/app/new-ticket/new-ticket.component';
import { UserProfileComponent } from 'src/app/user-profile/user-profile.component';
import { HomeComponent } from 'src/app/home/home.component';
import { RegisterComponent } from 'src/app/register/register.component';
import { LoginComponent } from 'src/app/login/login.component';
import { AuthInterceptorService } from './auth-interceptor.service';
import { CanActivateViaAuthGuard } from './can-activate-via-auth.guard';
import { TicketsComponent } from 'src/app/tickets/tickets.component';
import { TicketDetailComponent } from 'src/app/ticket-detail/ticket-detail.component';
import { OtpComponent } from 'src/app/otp/otp.component';
import { ForumComponent } from 'src/app/forum/forum.component';
import { ForumDetailComponent } from 'src/app/forum-detail/forum-detail.component';
import { UsersComponent } from 'src/app/users/users.component';
import { NewUserComponent } from 'src/app/new-user/new-user.component';
import { ShareTicketComponent } from 'src/app/share-ticket/share-ticket.component';
import { AddTopicComponent } from 'src/app/add-topic/add-topic.component';
import { UserDetailComponent } from 'src/app/user-detail/user-detail.component';
import { EditUserComponent } from 'src/app/edit-user/edit-user.component';
import { ChangePasswordComponent } from 'src/app/change-password/change-password.component';
import { ResetPasswordComponent } from 'src/app/reset-password/reset-password.component';
import { RecoverPasswordComponent } from 'src/app/recover-password/recover-password.component';
import { DepartmentsComponent } from 'src/app/departments/departments.component';
import { DepartmentDetailComponent } from 'src/app/department-detail/department-detail.component';
import { EditDepartmentComponent } from 'src/app/edit-department/edit-department.component';
import { NewDepartmentComponent } from 'src/app/new-department/new-department.component';
import { AssignTicketComponent } from 'src/app/assign-ticket/assign-ticket.component';
import { SendSmsComponent } from 'src/app/send-sms/send-sms.component';
import { BulkSmsComponent } from 'src/app/bulk-sms/bulk-sms.component';
import { SmsDetailComponent } from 'src/app/sms-detail/sms-detail.component';
import { ReportsComponent } from './reports/reports.component';
import { OfficialsComponent } from './officials/officials.component';
import { NewOfficialComponent } from './new-official/new-official.component';
import { EditOfficialComponent } from './edit-official/edit-official.component';
import {QueryTypesComponent} from './queryTypes/queryTypes.component';
import { NewQueryTypeComponent } from 'src/app/new-query-type/new-query-type.component';
import { ContactListComponent } from 'src/app/contact-list/contact-list.component';
import { ContactListDetailComponent } from 'src/app/contact-list-detail/contact-list-detail.component';
import { NewContactListComponent } from 'src/app/new-contact-list/new-contact-list.component';
import {TrendingReportsComponent} from 'src/app/trending-reports/trending-reports.component';
import {TrendingTopicsReportsComponent} from 'src/app//trending-topics-reports/trending-topics-reports.component';


const routes: Routes = [
  { path: 'queryTypes', component: QueryTypesComponent},
  {path: 'newQueryType', component: NewQueryTypeComponent},
  {path: 'contactList', component: ContactListComponent},
  {path: 'contactListDetails', component: ContactListDetailComponent},
  {path: 'newContactList', component: NewContactListComponent},
  { path: '', redirectTo: 'forum', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'verify', component: OtpComponent },
  { path: 'signup', component: RegisterComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'changepassword', component: ChangePasswordComponent },
  { path: 'recoverpassword', component: RecoverPasswordComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'forum', component: ForumComponent },
  { path: 'departments', component: DepartmentsComponent},
  { path: 'department', component: DepartmentDetailComponent},
  { path: 'editdepartment', component: EditDepartmentComponent},
  {path: 'newdepartment', component: NewDepartmentComponent},
  { path: 'contributions', component: ForumDetailComponent},
  {path: 'dashboard',component: HomeComponent},
  {path: 'assignofficial',component: AssignTicketComponent},
  {path: 'bulksms',component: BulkSmsComponent},
  {path: 'sendsms',component: SendSmsComponent},
  {path: 'sms',component: SmsDetailComponent},
  {path: 'reports',component: ReportsComponent},
  {path: 'officials',component: OfficialsComponent},
  {path: 'newofficial',component: NewOfficialComponent},
  {path: 'editofficial',component: EditOfficialComponent},
  {path: 'trendingReports',component: TrendingReportsComponent},
  {path: 'trendingForumTopics',component: TrendingTopicsReportsComponent},

  {
    path: 'profile',
    component: UserProfileComponent
  },
  {
    path: 'ticket',
    component: TicketDetailComponent
  },
  { path: 'tickets', component: TicketsComponent },
  { path: 'users', component: UsersComponent },
  { path: 'user', component: UserDetailComponent },
  { path: 'newticket', component: NewTicketComponent },
  { path: 'shareticket', component: ShareTicketComponent },
  { path: 'addtopic', component: AddTopicComponent },
  { path: 'newuser', component: NewUserComponent },
  { path: 'edituser', component: EditUserComponent },

];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
