import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  user:any;

  constructor(public authService:AuthService,public router:Router,public dataService:DataService) { }

  ngOnInit() {
  }
  currentRoute(){
    return this.router.url
  }



}
