import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-new-department',
  templateUrl: './new-department.component.html',
  styleUrls: ['./new-department.component.css']
})
export class NewDepartmentComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  form:FormGroup;

  constructor(private fb: FormBuilder,public router:Router) { 
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: []

  });
  }

  ngOnInit() {
  }
  addDepartment()
  {
    const val = this.form.value;
    this.connectionManager.post('/departments', res => {
      this.router.navigateByUrl('/departments');
  },{
      name: val.name,
      description:  val.description
     
  });
  }
}
