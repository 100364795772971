import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { ViewChild } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-department-detail',
  templateUrl: './department-detail.component.html',
  styleUrls: ['./department-detail.component.css']
})
export class DepartmentDetailComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  department:any;
 

  constructor(public dataService:DataService,public router:Router) { }

  ngOnInit() {
    this.department= this.dataService.getCurrentDepartment();
  }
  deleteDepartment()
  {
    if(confirm("Are you sure to delete Department?")) {
    this.connectionManager.delete('/departments/'+this.department.id,res=>
    {
      this.router.navigateByUrl('/departments');
    })
    }
  
  }
}
