import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { DataService } from 'src/app/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css']
})
export class ForumComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  topics=[];

  perPage = 3;
  before = 0;
  after = 0;
  recent = true
  filter='ALL';
  isOngoing=false;

  constructor(public authService:AuthService,public dataService:DataService,public router:Router) { }

  ngOnInit() {
    if(!this.authService.isAuthenticated())
    {
      this.isOngoing=true;
      this.connectionManager.get('/noAuthTopics?include=author,query-type&filter='+this.filter+'&before=0&after=0&perPage=3&recent=true', res=>{
        this.before = res.cursors.before;
        this.after = res.cursors.after;
        this.topics=res.data;
        this.isOngoing=false;

    })

    }
    this.isOngoing=true;
    this.connectionManager.get('/topics?include=author,query-type&filter='+this.filter+'&before=0&after=0&perPage=3&recent=true', res=>{
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      this.topics=res.data;
      this.isOngoing=false;

  })
  }

  addItems() {

    if(!this.authService.isAuthenticated())
    {
      if(this.isOngoing==false)
      {
        this.isOngoing=true;
        console.log('Requesting more before = '+this.before)
        this.connectionManager.get('/noAuthTopics?include=author,query-type&filter='+this.filter+'&before='+this.before+'&after='+this.after+'&perPage=3&recent=false', res=>{
          this.before = res.cursors.before;
          this.after = res.cursors.after;
          for(let item of res.data){
            this.topics.push(item)
          }
          this.isOngoing=false;

      })
      }

    }
    if(this.isOngoing==false)
    {
      this.isOngoing=true;
      console.log('Requesting more before = '+this.before)
      this.connectionManager.get('/topics?include=author,query-type&filter='+this.filter+'&before='+this.before+'&after='+this.after+'&perPage=3&recent=false', res=>{
        this.before = res.cursors.before;
        this.after = res.cursors.after;
        for(let item of res.data){
          this.topics.push(item)
        }
        this.isOngoing=false;

    })
    }

  }
  onScrollDown () {
    this.addItems()
  }

  addTopic()
  {
    this.router.navigateByUrl('/addtopic');
  }
  getAllTopics()
  {

    this.connectionManager.get('/topics?&include=author,query-type&filter='+this.filter+'&before=0&after=0&perPage=3&recent=true', res=>{
      this.topics = [];
      this.filter='ALL';
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      for(let item of res.data){
        this.topics.push(item)
      }
  })
  }
  getApprovedTopics()
  {
    this.connectionManager.get('/topics?&include=author,query-type&filter=APPROVED&before=0&after=0&perPage=3&recent=true', res=>{
      this.topics = [];
      this.filter='APPROVED';
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      for(let item of res.data){
        this.topics.push(item)
      }
  })
  }
  getPendingApprovalTopics()
  {
    this.connectionManager.get('/topics?&include=author,query-type&filter=PENDING_APPROVAL&before=0&after=0&perPage=3&recent=true', res=>{
      this.topics = [];

      this.filter='PENDING_APPROVAL';
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      for(let item of res.data){
        this.topics.push(item)
      }
  })
  }
  getRejectedTopics()
  {
    this.connectionManager.get('/topics?&include=author,query-type&filter=REJECTED&before=0&after=0&perPage=3&recent=true', res=>{
      this.topics = [];


      this.filter='REJECTED';
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      for(let item of res.data){
        this.topics.push(item)
      }
  })
  }

}
