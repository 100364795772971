import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
    tickets: any;
    user:any;
    constructor(private authService: AuthService,private apiService:ApiService, private router: Router) { }

    ngOnInit() {
        var string_user = localStorage.getItem('nccms_user');
        this.user=JSON.parse(string_user);
        this.connectionManager.get('/tickets?filter=latest&include=query-type.department&before=0&after=0&perPage=3&recent=true', res=>{
            this.tickets = res.data;
        })


    }


}
