import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-new-official',
  templateUrl: './new-official.component.html',
  styleUrls: ['./new-official.component.css']
})
export class NewOfficialComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  form:FormGroup;
  isOfficial:boolean=false;
  departments:any;
  smsNotificationsEnabled:any=true;
  isDepartmentAdmin:any;
  type:any;

  constructor(private fb: FormBuilder,public router:Router,public authService:AuthService) { 
    this.form = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required]

  });
  }

  ngOnInit() {
    

  }
  addUser()
  {
    const val = this.form.value;

      var departmentId = this.authService.user.departmentId;
      this.type = "OFFICIAL";

    this.connectionManager.post('/users', res => {
      this.router.navigateByUrl('/officials');
  },{
      phone: val.phone,
      name:  val.name,
      email:  val.email,
      type:  this.type,
      departmentId:  departmentId,
      smsNotificationsEnabled:this.smsNotificationsEnabled
  });
  }
  public smsNotificationsEnabledChanged(value:boolean){
    this.smsNotificationsEnabled = value;
    console.log(value);
}


}
