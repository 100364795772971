import { Injectable } from '@angular/core';
import { RequestOptions, Response } from '@angular/http';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';

@Injectable()
export class AuthService {

    API_URL = 'http://complaint-api.nyandarua.go.ke/api/v1';
    URL = 'http://complaint-api.nyandarua.go.ke';
    TOKEN_KEY = 'token';
    USER_DATA = 'nccms_user'
    USER='user';
    user:any

    constructor(private http: HttpClient, private router: Router) { 
        this.user = localStorage.getItem('nccms_user');
        console.log(this.user)
    }

    saveUser(user){
        this.user = user;
        localStorage.setItem(this.USER_DATA, JSON.stringify(user));
        localStorage.setItem(this.TOKEN_KEY,user.token);
    }

     token() {
        return this.user ? this.user.token : '';
    }

    isOfficial(){
        return this.user && this.user.type === 'OFFICIAL'
    }

    isAdmin(){
        return this.user && this.user.type === 'ADMIN'
    }

    isCitizen(){
        return this.user && this.user.type === 'CITIZEN'
    }
    isCs(){
        return this.user && this.user.type === 'CS'
    }
    isDepartmentAdmin(){
        return this.user && this.user.type === 'DEPARTMENT_ADMIN'
    }

     isAuthenticated() {
        return !!this.user
    }



    canActivateCheck() {
          return this.user;
      }

    getUserProfile()
    {
        var auth_user = JSON.parse(localStorage.getItem('nccms_user'));
        
        return auth_user;
    }

      logout() {
        this.user = null
        localStorage.setItem(this.USER_DATA, null);
        this.router.navigateByUrl('/forum');
    }
}