import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-ticket',
  templateUrl: './share-ticket.component.html',
  styleUrls: ['./share-ticket.component.css']
})
export class ShareTicketComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  ticket:any;
  form:FormGroup

  constructor(public fb:FormBuilder,public dataService:DataService,public router:Router) {
    this.form = this.fb.group({
      reason: ['', Validators.required]
     
      
  });
   }

  ngOnInit() {
    this.ticket = this.dataService.getCurentTicket();
  }
  shareToTeam()
  {
    const val = this.form.value;
    this.connectionManager.post('/tickets/'+this.ticket.id+'/share',res=>
    {
      this.router.navigateByUrl('/tickets');
    },{
      reason:val.reason
    })


  }

}
