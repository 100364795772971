import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
var element;
@Component({
  selector: 'app-new-contact-list',
  templateUrl: './new-contact-list.component.html',
  styleUrls: ['./new-contact-list.component.css']
})

export class NewContactListComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  form:FormGroup;
  selectedFile: File;


  onFileChanged(event) {
    console.log(event.target.id);
    element = event.target;
    this.selectedFile = event.target.files[0]
    let file: File = event.target.files[0];
    //console.log("FILE DATA:",file);
  }

  constructor(private fb: FormBuilder,public router:Router) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      fileName: ['', Validators.required],


  });
  }

  ngOnInit() {
  }
  addContactList()
  {
    const val = this.form.value;
    let contactListName = val.name;

    let fileToUpload: File = element.files[0];
   // console.log(fileToUpload);
    //console.log("fileToupload " + fileToupload)
    const fd = new FormData();
    fd.append("name" ,contactListName);
    fd.append('file', fileToUpload);
    console.log("formdata" + fd);
    this.connectionManager.post('/contactGroups', res => {
      alert(res.meta.message);
      console.log(res);
           this.router.navigateByUrl('/newContactList');
       },fd );
  }

}
