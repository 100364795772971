import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-assign-ticket',
  templateUrl: './assign-ticket.component.html',
  styleUrls: ['./assign-ticket.component.css']
})
export class AssignTicketComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  officials:any;
  ticket:any;
  form:FormGroup;

  
    constructor(public dataService:DataService,private fb: FormBuilder,private apiService:ApiService,private router: Router,public authService:AuthService) 
    {
      this.form = this.fb.group({
        officialId: ['', Validators.required]
        
    });
     }

  ngOnInit() {
    var selected_ticket = localStorage.getItem('current_ticket');
    this.ticket=JSON.parse(selected_ticket);
    this.connectionManager.get('/users?type=OFFICIAL&before=0&after=0&perPage=1000&recent=true',res=>
    {
      this.officials = res.data
    });
  }
  assignOfficial()
  {
    const val = this.form.value;
      const officialId=val.officialId;
        this.connectionManager.post('/tickets/'+this.ticket.id+'/assign/'+officialId,res=>
        {
          this.router.navigateByUrl('/ticket');
        },{})
  }

}
