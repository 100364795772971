///<reference path="../../../node_modules/@angular/core/src/metadata/directives.d.ts"/>
import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-new-query-type',
  templateUrl: './new-query-type.component.html',
  styleUrls: ['./new-query-type.component.css']
})
export class NewQueryTypeComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  form:FormGroup;
  data:any;
  ticket:any;
  selected:any=[];
  display:any='none';
  details:any;
  department:any;
  departments:any;


  rateCount:any;
  rateDescription:string='';
  ratingForm:FormGroup;

  constructor(private fb: FormBuilder,public router:Router) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      departmentId: ['', Validators.required],

  });
  }

  ngOnInit() {
    this.connectionManager.get('/departments',res=>
    {
      console.log(res);
      this.departments = res.data
    });
  }

  addQueryType(){
    const val = this.form.value;
    this.connectionManager.post('/queryTypes', res => {
        this.router.navigateByUrl('/queryTypes');
      },
      {
        name: val.name,
        departmentId:  val.departmentId
      });

  }

}
