import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  tickets: any;
  perPage = 3;
  before = 0;
  after = 0;
  recent = true;
  isOngoing=false;

  constructor(public apiService:ApiService,public router:Router,public dataService:DataService) { }

  ngOnInit() {
    this.isOngoing=true;
    this.connectionManager.get('/tickets?include=citizen,query-type,query-type.department,ward,share-approved-by,completed-by,started-by&before=0&after=0&perPage=3&recent=true', res=>{
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      this.tickets=res.data;
      this.isOngoing=false;
  })

}

addItems() {
  if(this.isOngoing==false)
  {
    this.isOngoing=true;
    this.connectionManager.get('/tickets?include=citizen,query-type,query-type.department,share-approved-by,completed-by,started-by&before='+this.before+'&after='+this.after+'&perPage=3&recent=false', res=>{
      this.isOngoing=false;
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      for(let item of res.data){
        this.tickets.push(item)
      }

  })
  }

}
onScrollDown () {
  this.addItems()
}

setTicket(ticket:Object)
{
  console.log("ticket details: " + JSON.stringify(ticket));
  this.dataService.setCurrentTicket(ticket);

  localStorage.setItem('current_ticket', JSON.stringify(ticket));
  this.router.navigateByUrl('/ticket');
}

}
