import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
    constructor(private authService: AuthService,private router: Router) {

    }

    canActivate() {
        // return this.authService.isAuthenticated;
        // if (this.authService.isAuthenticated) {
        //     this.router.navigate(['dashboard']);
        //     return true;
        // }
        // else {
        //     this.router.navigate(['login']);
        // }
        // return false;
        // if (!this.authService.isAuthenticated) {
        //     this.router.navigate(['login']);
        //     return false;
        //   }
        //   return true;
        let isUserLoggedIn = this.authService.canActivateCheck();
        
              if (!isUserLoggedIn) {
                this.router.navigate(['/login'])
              }
        
              return isUserLoggedIn;
    }
}