import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.css']
})
export class SendSmsComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  form:FormGroup;
  data:any;
  meta:any;
  ticket:any=null;
  type:any;
  isOfficial:boolean=false;
  isContactGroup:boolean=false;
  departments:any;
  contactGroups:any;

  constructor(private fb: FormBuilder,public router:Router) {
    this.form = this.fb.group({
      message: ['', Validators.required],
      type: ['', Validators.required],
      departmentId: [],
      contactGroupId: [],

  });
  }

  getType(type){
    if(type==='OFFICIALS')
    {
      this.isOfficial=true;
      this.isContactGroup=false;
    }else if(type==='CONTACT_GROUP'){
      this.isContactGroup=true;
      this.isOfficial=false;
    }
    else if (type==='CITIZENS')
    {
      this.isContactGroup=false;
      this.isOfficial=false;
    }
  }
  ngOnInit() {
      this.connectionManager.get('/departments', res=>{
        this.departments = res.data;
        console.log(res.data);
      });
    this.connectionManager.get('/contactGroups', res=>{
      this.contactGroups = res.data;
      console.log(res.data);
    })
  }
  sendSms()
  {
    const val = this.form.value;
    this.connectionManager.post('/bulkSms', res => {
      //this.msg = res.meta;
      alert(res.meta.message);
      this.router.navigateByUrl('/bulksms');
  },{
      type:val.type,
      text:  val.message,
      departmentId: val.type ==='OFFICIALS'? val.departmentId : 'null',
      contactGroupId: val.type ==='CONTACT_GROUP'? val.contactGroupId : 'null'
  });
  }
}
