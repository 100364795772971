import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css']
})
export class ContactListComponent implements OnInit {
  contactGroups: any;
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;

  constructor(public apiService:ApiService,public router:Router,public dataService:DataService) { }

  ngOnInit() {
    this.connectionManager.get('/contactGroups?include=last-edited-by,created-by,deleted-by', res=>{
      this.contactGroups = res.data;
  })
}
  viewContactGroup(contactGroup:Object)
{
 console.log(contactGroup);
  this.dataService.setCurrentContactGroup(contactGroup);
  this.router.navigateByUrl('/contactListDetails');
}
}
