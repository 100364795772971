import { Component, OnInit } from '@angular/core';
import { ConnectionManagerComponent } from 'src/app/connection-manager/connection-manager.component';
import { ViewChild } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { AuthService } from 'src/app/auth.service';
import { FormGroup } from '@angular/forms/src/model';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forum-detail',
  templateUrl: './forum-detail.component.html',
  styleUrls: ['./forum-detail.component.css']
})
export class ForumDetailComponent implements OnInit {
  @ViewChild(ConnectionManagerComponent) connectionManager:ConnectionManagerComponent;
  contributions:any;
  form:FormGroup;
  currentTopic:any;

  perPage = 3;
  before = 0;
  after = 0;
  isOngoing=false;
  message:any;

  constructor(private fb: FormBuilder,public dataService:DataService, public authService:AuthService,public router:Router) {
    this.form = this.fb.group({
      text: ['', Validators.required]
     
      
  });
   }

  ngOnInit() {
    this.isOngoing=true;
    this.currentTopic = this.dataService.currentTopic;
    this.connectionManager.get('/topics/'+this.dataService.currentTopic.id+'/contributions?&include=author&before='+this.before+'&after='+this.after+'&perPage=3&recent=false', res=>{
      this.before = res.cursors.before;
      this.after = res.cursors.after;
      this.contributions=res.data;
      this.isOngoing=false;
  })
  }
  addItems() {
    if(this.isOngoing==false)
    {
      this.isOngoing=true;
      console.log('Requesting more before = '+this.before)
      this.connectionManager.get('/topics/'+this.dataService.currentTopic.id+'/contributions?&include=author&before='+this.before+'&after='+this.after+'&perPage=3&recent=false', res=>{
        this.before = res.cursors.before;
        this.after = res.cursors.after;
        for(let item of res.data){
          this.contributions.push(item)
        }
        this.isOngoing=false;
    
    })
    }

  }
  onScrollDown () {
    this.addItems()
  }
  sendMessage()
  {
    const val = this.form.value;
    
    this.connectionManager.post('/topics/'+this.currentTopic.id+'/contributions', res=>{
      this.message='';

    this.contributions.push({'id':res.data.id,'text':res.data.text,'createdAt':res.data.createdAt,'updatedAt':res.data.updatedAt,author:{id:null,name:'Me'}});
  },{
    text:val.text
  })
  }
  approveTopic()
  {
    this.connectionManager.post('/topics/'+this.currentTopic.id+'/approve?include=author', res=>{
        // this.currentTopic = null;
        this.currentTopic = res.data
          
        },{})
  }
  rejectTopic()
  {
    this.connectionManager.post('/topics/'+this.currentTopic.id+'/reject?include=author', res=>{
      this.currentTopic = null;
      this.currentTopic = res.data
        
      },{})
  }

}
